.btn{ 
    border-radius: 0px;
    transition: all .5s ease-out;
} 

.form{
  border-radius: 0px;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.form:hover{
  border-color: #6610f2;
}
.row{
  padding-bottom: 10px;
}

.card { 
  height: 100px;
  padding-top: 10px;
  padding: 10px;
  padding-left: 10px;
}
.card:hover{
  border-color: #6610f2;
  background-color: #6610f2;
  color: aliceblue;
}