ul {
    list-style: none;
  }
  
  ul li:before {
    content: '✓';
  }

  .centerss {
    display: flex;
    justify-content: center;
    align-items: center;
}
.rightss {
    display: flex;
    justify-content: right;
    align-items: right;
}